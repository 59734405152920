import type { ValidationErrorReasons, WarmupV2EditForm, WarmupV2ValidationResult } from "@newgenerated/shared/schema";
import memoize from "lodash/memoize";
import { validateWarmupV2EditForm } from "@generated/api/warmupV2ControllerApi";
import type { DeepReadonly } from "vue";
import { type Result, saveAsyncRequest } from "@/common/requestUtils";
import isEqual from "lodash/isEqual";
import type { Store } from "@/common/storeUtils";

// Warmup V2 specific

export type WarmupV2FormMeta = {
  errors: ValidationErrorReasons;
  changedFields: Set<string> | "ALL";
};

const mem = memoize(validateWarmupV2EditForm, JSON.stringify);

export async function validate(getForm: () => DeepReadonly<WarmupV2EditForm>): Promise<Result<WarmupV2ValidationResult>> {
  return await saveAsyncRequest(mem, (prev) => isEqual(getForm(), prev), getForm());
}

export function getErrors(store: Store<WarmupV2FormMeta>, field: keyof WarmupV2EditForm): DeepReadonly<string[]> {
  const fieldErrorsStore = store.sub("errors").sub("fields").sub("additionalProperties");
  const changedFieldsStore = store.sub("changedFields");

  const fieldErrors = fieldErrorsStore.get()[field] ?? [];
  const changedFields = changedFieldsStore.get();
  if (changedFields === "ALL" || changedFields.has(field)) {
    return fieldErrors;
  }
  return [];
}
