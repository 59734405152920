import "./bootstrap-5-default.scss";

import "@popperjs/core/dist/umd/popper.min";

import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/alert";
import Collapse from "bootstrap/js/dist/collapse";
import Tooltip from "bootstrap/js/dist/tooltip";
import Popover from "bootstrap/js/dist/popover";
import Modal from "bootstrap/js/dist/modal";
import "@/extensions/bootstrap-table";

import Splide from "@splidejs/splide";

import "@/components/_chip";
import "@/components/form/_form-field-character-counter";
import "@/components/form/_form-field-select";
import "@/components/actionable/_actionable-card";
import "@/components/actionable/_actionable-certificate-popup";
import "@/components/mylist/_mylist-header";
import "@/components/actionable/actionable-page/_actionable-course-progress";
import "@/components/actionable/actionable-page/_actionable-video-progress";
import "@/components/_collapse-label-toggle";
import "@/components/_enterprise-contact-form";
import "@/components/audio-player/_audio-player";
import "@/components/channel/channel-insight/_channel-insight";
import "@/components/bot-protection/_bot-protection";
import "@/components/analytic/_ga-analytic";
import "@/components/analytic/_outbound-link-tracking";
import "@/components/origin-cleaner/_origin-cleaner";
import "@/components/actionable/_actionable-overview";
import "@/components/portlet/_portlet-loader";
import "@/components/promobox/_promobox-resizing";
import "@/components/prospect/prospect-flower/_prospect-flower";
import "@/components/promobox/_promobox-expand";
import "@/components/instant-search/_instant-search";
import "@/components/webapp/_webapp-layout-collapse";
import Offcanvas from "@/components/general/_offcanvas";
import "@/components/general/_tooltip";
import { getCsrfToken } from "@utils/csrfUtils";
import "@/common/session-keepalive";
import "@/components/actionable/actionable-page/_actionable-width-toggler";
import "@/components/_clientside-filter";
import "@/components/search/FullSearch";

import "@newgenerated/widgets/DefaultWidgets";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).getCsrfToken = getCsrfToken;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).splide = {
  Splide,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).bootstrap = {
  Tooltip,
  Popover,
  Collapse,
  Modal,
  Offcanvas,
};
