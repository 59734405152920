import { ensureNonNull } from "@utils/assertion";
import { type SearchFiltersForm } from "@newgenerated/shared/schema";
import { serialize_SearchFiltersForm, deserialize_SearchFiltersForm } from "@newgenerated/shared/mapper";

export { type SearchFiltersForm } from "@newgenerated/shared/schema";
export const parseSearchFiltersForm = (data: unknown): SearchFiltersForm => {
  return ensureNonNull(deserialize_SearchFiltersForm(JSON.stringify(data)));
};
export const renderSearchFiltersForm = (data: SearchFiltersForm): any => {
  return JSON.parse(serialize_SearchFiltersForm(data));
};
