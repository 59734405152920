import type { GaVueComponent } from "@/common/vueUtils";
import { Answer } from "@/components/getabstract-ai/uiFragments/Answer";
import { Feedback } from "@/components/getabstract-ai/uiFragments/Feedback";
import { References } from "@/components/getabstract-ai/uiFragments/References";
import type { PropType } from "vue";
import { type DeepReadonly, defineComponent, ref } from "vue";
import { GaCollapse } from "@/components/general/GaCollapse";
import { RelatedActionables } from "@/components/getabstract-ai/uiFragments/RelatedActionables";
import type { ComponentUiState, QuestionAnswer } from "@/components/getabstract-ai/utils/getabstractAiStore";

function AnswerEntry(props: {
  entry: DeepReadonly<QuestionAnswer>;
  giveFeedback: (questionUuid: string, isPositive: boolean) => void;
  toggleHistoryEntry: (questionUuid: string, show: boolean) => void;
  toggleBookmark: (dataId: number, bookmarked: boolean) => void;
  feedbackIsPositive: (questionUuid: string) => boolean | null;
  summaryIsBookmarked: (dataId: number) => boolean;
}): GaVueComponent | null {
  return (
    <div class="d-grid gap-4">
      <Answer answer={props.entry.answer} contextDocs={props.entry.contextDocs} questionUuid={props.entry.questionUuid} />
      <Feedback question={props.entry.question} questionUuid={props.entry.questionUuid} feedbackIsPositive={props.feedbackIsPositive(props.entry.questionUuid)} giveFeedback={props.giveFeedback} border={true} />
      <References
        contextDocs={props.entry.contextDocs}
        answer={props.entry.answer}
        questionUuid={props.entry.questionUuid}
        border={true}
        toggleBookmark={(dataId, bookmarked) => props.toggleBookmark(dataId, bookmarked)}
        summaryIsBookmarked={props.summaryIsBookmarked}
      />
      <RelatedActionables relatedActionables={props.entry.relatedActionables} questionUuid={props.entry.questionUuid} border={true} />
    </div>
  );
}

export const HistoryEntry = defineComponent({
  props: {
    entry: {
      type: Object as PropType<DeepReadonly<QuestionAnswer>>,
      required: true,
    },
    giveFeedback: {
      type: Function as PropType<(questionUuid: string, isPositive: boolean) => void>,
      required: true,
    },
    toggleBookmark: {
      type: Function as PropType<(dataId: number, bookmarked: boolean) => void>,
      required: true,
    },
    feedbackIsPositive: {
      type: Function as PropType<(questionUuid: string) => boolean | null>,
      required: true,
    },
    summaryIsBookmarked: {
      type: Function as PropType<(dataId: number) => boolean>,
      required: true,
    },
  },
  setup(props) {
    const visible = ref(false);

    return () => (
      <GaCollapse title={props.entry.question} visible={visible.value} onUpdateVisible={(show) => (visible.value = show)} id={props.entry.questionUuid}>
        <AnswerEntry
          entry={props.entry}
          giveFeedback={props.giveFeedback}
          toggleHistoryEntry={() => (visible.value = !visible.value)}
          toggleBookmark={props.toggleBookmark}
          feedbackIsPositive={props.feedbackIsPositive}
          summaryIsBookmarked={props.summaryIsBookmarked}
        />
      </GaCollapse>
    );
  },
});

export function History(props: {
  state: DeepReadonly<ComponentUiState>;
  giveFeedback: (questionUuid: string, isPositive: boolean) => void;
  toggleBookmark: (dataId: number, bookmarked: boolean) => void;
  feedbackIsPositive: (questionUuid: string) => boolean | null;
  summaryIsBookmarked: (dataId: number) => boolean;
}): GaVueComponent | null {
  if (props.state.kind === "INITIAL" || props.state.value.history.length === 0) {
    return null;
  }

  return (
    <div class="d-grid gap-3">
      {props.state.value.history.map((qa) => (
        <HistoryEntry entry={qa} giveFeedback={props.giveFeedback} key={qa.questionUuid} toggleBookmark={props.toggleBookmark} feedbackIsPositive={props.feedbackIsPositive} summaryIsBookmarked={props.summaryIsBookmarked} />
      ))}
    </div>
  );
}
