import { GaButton } from "@/components/general/GaButton";
import { defineComponent, ref } from "vue";
import { type ConfirmModalConfig, useConfirmationModal } from "@/components/general/GaModal";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";

const ConfirmationModalDemo = defineComponent({
  setup() {
    const config = ref<Required<ConfirmModalConfig>>({ title: "Confirmation Modal Example", message: "Choose an option:", cancelLabel: "", confirmLabel: "" });
    const log = ref<boolean[]>([]);
    const confirmWithModal = useConfirmationModal();

    async function handleConfirm(): Promise<void> {
      log.value.push(await confirmWithModal({ ...config.value }));
    }

    return () => (
      <div class="row">
        <div class="col-9 col-md-8">
          <h3>Confirmation Modal</h3>
          <form
            class="d-grid gap-4"
            onSubmit={async (e) => {
              e.preventDefault();
              await handleConfirm();
            }}>
            <GaFormFieldInputText name="title" label="Title (optional)" value={config.value.title} onUpdateValue={(value) => (config.value.title = value)} />
            <GaFormFieldInputText name="message" label="Message" value={config.value.message} onUpdateValue={(value) => (config.value.message = value)} />
            <GaFormFieldInputText name="cancelLabel" label="Cancel Label (optional)" value={config.value.cancelLabel} onUpdateValue={(value) => (config.value.cancelLabel = value)} />
            <GaFormFieldInputText name="confirmLabel" label="Confirm Label (optional)" value={config.value.confirmLabel} onUpdateValue={(value) => (config.value.confirmLabel = value)} />
            <GaButton>Create Confirmation Modal</GaButton>
          </form>
        </div>
        <div class="col-3 col-md-4">
          <h4 class="mt-3">Log</h4>
          {log.value.length > 0 ? (
            <ul>
              {log.value.map((choice) => (
                <li>{choice ? <span style="color: var(--ga-success);">Confirmed</span> : <span style="color: var(--ga-danger);">Cancelled</span>}</li>
              ))}
            </ul>
          ) : (
            <em>no confirmation modal closed yet</em>
          )}
        </div>
      </div>
    );
  },
});

export const ModalDemo = defineComponent({
  setup() {
    return () => (
      <>
        <h2>Vue</h2>
        <ConfirmationModalDemo />
      </>
    );
  },
});
