import { useI18n } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";
import type { DeepReadonly } from "vue";
import { GaButton } from "@/components/general/GaButton";
import { ReferenceSnippet } from "@/components/getabstract-ai/uiFragments/ReferenceSnippet";
import type { GetabstractAiAnswerContextDoc } from "@generated/model/getabstractAiAnswerContextDoc";
import { sortReferencesByIndex } from "@/components/getabstract-ai/utils/getabstractAiAnswerUtils";

function ReferenceCard(props: {
  reference: DeepReadonly<GetabstractAiAnswerContextDoc>;
  questionUuid: string;
  hideReferenceNumber?: boolean;
  border?: boolean;
  toggleBookmark: (dataId: number, bookmarked: boolean) => void;
  summaryIsBookmarked: (dataId: number) => boolean;
}): GaVueComponent {
  const { t } = useI18n();

  const isBookmarked = props.summaryIsBookmarked(props.reference.dataId);

  return (
    <div class={`summary-card-referenced${props.border === true ? " summary-card-referenced--border" : ""}`} data-ga-data-id={props.reference.dataId}>
      <div class="summary-card-referenced__rating-source">
        {props.reference.rating !== null ? (
          <span class="summary-card__rating" title={t("summary:rating.ga.title")}>
            <i class="ico-star-fill summary-card__rating-icon"></i> {props.reference.rating}
          </span>
        ) : null}
        <span class="summary-card__source caption">{props.reference.sourceTypeString}</span>
      </div>
      <div class="summary-card-referenced__actions">
        <GaButton variant="link" onClick={() => props.toggleBookmark(props.reference.dataId, !isBookmarked)}>
          <i class={`ico-bookmark${isBookmarked ? "-fill" : ""}`}></i>
        </GaButton>
      </div>
      <a class="summary-card-referenced__cover-link" href={props.reference.urls.list} tabindex="-1" aria-hidden="true" target="_blank">
        <img src={`${props.reference.urls.cover}?s=S`} alt="" class="summary-card-referenced__cover" />
      </a>

      <a href={props.reference.urls.list} class="summary-card-referenced__title-link" target="_blank">
        <h3 class="summary-card-referenced__title">{props.reference.title}</h3>
      </a>

      <ReferenceSnippet snippet={props.reference.snippet}></ReferenceSnippet>

      {props.hideReferenceNumber === true ? null : <div class="summary-card-referenced__reference-number">{props.reference.referenceNumber}</div>}
    </div>
  );
}

export function References(props: {
  contextDocs: DeepReadonly<GetabstractAiAnswerContextDoc[]>;
  answer: string;
  questionUuid: string;
  border?: boolean;
  toggleBookmark: (dataId: number, bookmarked: boolean) => void;
  summaryIsBookmarked: (dataId: number) => boolean;
}): GaVueComponent | null {
  const { t } = useI18n();

  const sortedByIndex = sortReferencesByIndex(props.answer, props.contextDocs);
  if (sortedByIndex.length === 0) {
    return null;
  }

  return (
    <div class="d-grid gap-3">
      <h4 class="m-0">{t("search:askGa.sources.title")}</h4>
      <div class="row g-3">
        {sortedByIndex.map((reference) => (
          <div class="col-12" key={reference.referenceNumber}>
            <ReferenceCard reference={reference} questionUuid={props.questionUuid} toggleBookmark={props.toggleBookmark} border={props.border} summaryIsBookmarked={props.summaryIsBookmarked} />
          </div>
        ))}
      </div>
    </div>
  );
}
