<template>
  <a :href="href" :target="linkTarget" class="customPage-card-mini">
    <img :src="coverUrl" alt="" class="customPage-card-mini__img" />
    <div class="customPage-card-mini__title">
      {{ customPage.title }}
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CustomPageListView } from "@generated/model/customPageListView";
import "./_customPageCardMini.scss";

export default defineComponent({
  props: {
    customPage: {
      type: Object as PropType<CustomPageListView>,
      required: true,
    },
    linkTarget: {
      type: String,
    },
    params: {
      type: Object as PropType<URLSearchParams>,
    },
  },
  setup(props) {
    const url = new URL("/custom-pages/" + props.customPage.customPageId, window.location.origin);
    if (props.params !== undefined) {
      for (const [key, value] of props.params) {
        url.searchParams.append(key, value);
      }
    }
    const coverUrl = props.customPage.coverUri + "?s=L";
    return {
      href: url.href,
      coverUrl,
    };
  },
});
</script>
