import type { GaVueComponent } from "@/common/vueUtils";
import { NoResults } from "@/components/search/NoResults";
import { useI18n } from "@/i18n/i18nSetup";
import type { ContentTypeProps, ExtendedContentType, FilterOption, SearchStatus, SearchStoreActions, SummariesPaging } from "@/components/search/fullSearchStoreTypes";
import { ContentType } from "@/components/search/ContentType";
import type { DeepReadonly } from "vue";
import type { MultiSelectProps } from "@/components/form/GaFormFieldMultiSelect";
import type { FormFilter } from "@newgenerated/shared/schema";

function ErrorState(): GaVueComponent {
  const { t } = useI18n();
  return (
    <div>
      <h2 class="h3">{t("search:page.search.errorTitle")}</h2>
      <p>{t("search:page.search.errorDescription")}</p>
    </div>
  );
}

export function SearchResults(props: {
  status: SearchStatus;
  actions: SearchStoreActions;
  multiSelectProps: DeepReadonly<MultiSelectProps<string>>;
  contentTypeProps: DeepReadonly<ContentTypeProps[]>;
  selectedContentType: ExtendedContentType;
  formFilters: DeepReadonly<FormFilter[]>;
  filtersExtended: boolean;
  summariesPaging: SummariesPaging;
  params: URLSearchParams;
  filterOptions: DeepReadonly<FilterOption[]>;
}): GaVueComponent {
  const filteredContentTypes = props.contentTypeProps.filter((value) => props.selectedContentType === "ALL" || props.selectedContentType === value.discriminator);

  if (props.status === "ERROR") {
    return <ErrorState />;
  }
  return (
    <>
      {filteredContentTypes.some((ct) => ct.items.length > 0) || props.status === "LOADING" ? (
        <>
          {filteredContentTypes.map((contentType) => (
            <div class="mb-4">
              <ContentType
                selectContentType={props.actions.selectContentType}
                contentType={contentType}
                searchStatus={props.status}
                formFilters={props.formFilters}
                selectedContentType={props.selectedContentType}
                actions={props.actions}
                filtersExtended={props.filtersExtended}
                msProps={props.multiSelectProps}
                loadMoreSummaries={props.actions.loadMoreSummaries}
                summariesPaging={props.summariesPaging}
                params={props.params as URLSearchParams /* Using `DeepReadonly` looses the type information of `URLSearchParams` */}
                filterOptions={props.filterOptions}
                toggleFilter={props.actions.toggleSingleFilter}
              />
            </div>
          ))}
        </>
      ) : (
        <div class="py-3">
          <NoResults resetFilters={props.actions.resetAllFilters} />
        </div>
      )}
    </>
  );
}
