import type { SelectOption } from "@/components/form/GaFormFieldInputSelect";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaChip } from "@/components/general/GaChip";
import "./_gaFormFieldMultiSelect.scss";
import { defineComponent, type PropType, ref } from "vue";
import { delay } from "@utils/asyncUtils";

export type MultiSelectStateProps<T> = {
  options: SelectOption<T>[];
  selectedOptions: SelectOption<T>[];
  searchTerm: string;
  showSearch: boolean;
};

export type MultiSelectActions<T> = {
  onChange: (options: SelectOption<T>[]) => void;
  onSearchTermChange: (value: string) => void;
  toggleSearch: () => void;
};

export type MultiSelectProps<T> = MultiSelectStateProps<T> & MultiSelectActions<T>;

function getSelectedItemsWithout<T>(optionToRemove: SelectOption<T>, options: SelectOption<T>[]): SelectOption<T>[] {
  return options.filter((option) => option.value !== optionToRemove.value);
}

const MultiSelectContent = defineComponent({
  props: {
    toggleSearch: {
      type: Function as PropType<() => void>,
      required: true,
    },
    options: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<SelectOption<any>[]>,
      required: true,
    },
    selectedOptions: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<SelectOption<any>[]>,
      required: true,
    },
    onChange: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Function as PropType<(value: SelectOption<any>[]) => void>,
      required: true,
    },
    showSearch: {
      type: Boolean,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    onSearchTermChange: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
  },
  setup: (props) => {
    const id = window.crypto.randomUUID().substring(0, 7);
    const input = ref<InstanceType<typeof HTMLInputElement> | undefined>(undefined);

    async function togglePopup(event: MouseEvent): Promise<void> {
      event.stopPropagation();
      props.toggleSearch();
      if (!props.showSearch) {
        await delay(0);
        input.value?.focus();
        document.addEventListener("click", toggleHelperFunction);
      }
    }

    function toggleHelperFunction(event: MouseEvent): void {
      void togglePopup(event);
      document.removeEventListener("click", toggleHelperFunction);
    }

    return () => (
      <div class="ga-multiselect" onClick={(event) => event.stopPropagation()}>
        <div class="form-control ga-multiselect__selected d-flex flex-wrap gap-1" onClick={(event) => togglePopup(event)}>
          {props.selectedOptions.map((option) => (
            <GaChip type="removable" removeHandler={() => props.onChange(getSelectedItemsWithout(option, props.selectedOptions))}>
              {option.label}
            </GaChip>
          ))}
        </div>
        <div class={["ga-multiselect__search", props.showSearch ? "show" : ""]} onBlur={() => console.log("serch blur")}>
          <input id={id} ref={input} class="form-control ga-multiselect__input" type="text" value={props.searchTerm} onInput={(e) => props.onSearchTermChange((e.target as HTMLInputElement).value)} />
          {props.options.filter((option) => !props.selectedOptions.includes(option)).filterLike("label", props.searchTerm).length > 0 ? (
            <ul class="ga-multiselect__search-list">
              {props.options
                .filter((option) => !props.selectedOptions.includes(option))
                .filterLike("label", props.searchTerm)
                .map((option) => (
                  <li class="ga-multiselect__search-list-item" onClick={() => props.onChange([...props.selectedOptions, option])}>
                    {option.label}
                  </li>
                ))}
            </ul>
          ) : null}
        </div>
      </div>
    );
  },
});

export function GaFormFieldMultiSelect<T>(props: MultiSelectProps<T>): GaVueComponent {
  return (
    <MultiSelectContent
      showSearch={props.showSearch}
      toggleSearch={props.toggleSearch}
      options={props.options}
      selectedOptions={props.selectedOptions}
      onChange={props.onChange}
      searchTerm={props.searchTerm}
      onSearchTermChange={props.onSearchTermChange}
    />
  );
}
