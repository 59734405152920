import type { ActionableListView, ChannelMetadata, CustomPageListView, FeatureType, FormFilter, FullSearchResults, GetabstractAiQuestionAnswers, SearchFiltersForm, SummaryMetadata } from "@newgenerated/shared/schema";
import type { CreateQuestionPropertiesExtended } from "@/components/getabstract-ai/utils/getabstractAiRequests";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import type { MultiSelectActions, MultiSelectStateProps } from "@/components/form/GaFormFieldMultiSelect";
import type { ComponentSimpleInteractions, SimpleComponentUiState } from "@/components/getabstract-ai/utils/getabstractAiBackgroundWorker";

export type FullSearchStoreApiMethods = {
  search: (searchFiltersForm: SearchFiltersForm) => Promise<FullSearchResults>;
  createQuestion: ({ question, questionUuid, previousQuestionUuid, relatedQuestionStep, trendingQuestion, blacklistQuestions, analyticsEventVariant }: CreateQuestionPropertiesExtended) => Promise<void>;
  getQuestionAnswers: (questionUuid: string) => Promise<GetabstractAiQuestionAnswers>;
  hasFeature: (feature: FeatureType) => Promise<boolean>;
};

export const defaultAmount: { [content in ContentTypes]: number } = {
  SUMMARY: 4,
  ACTIONABLE: 6,
  CHANNEL: 9,
  CUSTOMPAGE: 9,
};

export type DataType =
  | {
      discriminator: "SUMMARY";
      items: SummaryMetadata[];
    }
  | {
      discriminator: "CHANNEL";
      items: ChannelMetadata[];
    }
  | {
      discriminator: "CUSTOMPAGE";
      items: CustomPageListView[];
    }
  | {
      discriminator: "ACTIONABLE";
      items: ActionableListView[];
    };
export type ContentTypeProps = DataType & {
  amountToShow: number;
  title: string;
};

export const filterTypes = ["audioFormFilter", "languageFormFilter", "sourceFormFilter", "qualityFormFilter", "ratingFormFilter", "publicationDateFormFilter"] as const;
export type FilterType = (typeof filterTypes)[number];
export type ExtendedContentType = ContentTypes | "ALL";
export type UpdateFilter = (filterName: FilterType, newActiveValues: string[]) => void;
export type ResetFilter = (filterType: FilterType) => void;
export type FilterOption = { filterName: string; isOpen: boolean };

export type UiState = {
  filtersExtended: boolean;
  multiSelectProps: MultiSelectStateProps<string>;
  filterOptions: FilterOption[];
};

export type SearchAiComponentState =
  | { feature: "NOT_AVAILABLE" }
  | {
      feature: "AVAILABLE";
      uiState: SimpleComponentUiState;
      displayFullAnswer: boolean;
    };

export type SummariesPaging = {
  page: number;
  totalCount: bigint;
};

export type SearchStatus = "LOADING" | "LOADMORE" | "IDLE" | "ERROR";

export type SearchStoreState = UiState &
  ComponentSimpleInteractions & {
    contentTypeProps: ContentTypeProps[];
    formFilters: FormFilter[];
    selectedContentType: ExtendedContentType;
    status: SearchStatus;
    summariesPaging: SummariesPaging;
    params: URLSearchParams;
    aiState: SearchAiComponentState;
  };

export type SearchStoreActions = MultiSelectActions<string> & {
  search: (softReload?: boolean) => Promise<void>;
  selectContentType: (contentType: ExtendedContentType) => void;
  updateFilter: UpdateFilter;
  resetFilter: ResetFilter;
  resetAllFilters: () => void;
  toggleFilters: () => void;
  loadMoreSummaries: () => Promise<void>;
  toggleSingleFilter: (filterName: string) => void;
  showFullAiAnswer: () => void;
};
