import type { DeepReadonly } from "vue";
import type { FormFilter } from "@newgenerated/shared/schema";
import type { FilterOption, UpdateFilter } from "@/components/search/fullSearchStoreTypes";
import type { MultiSelectProps } from "@/components/form/GaFormFieldMultiSelect";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { Filter } from "@/components/search/Filter";
import { GaButton } from "@/components/general/GaButton";
import { assertIsDefined } from "@utils/assertion";

function getFilterOptionByName(name: string, filterOptions: DeepReadonly<FilterOption[]>): FilterOption {
  const filterOption = filterOptions.find((value) => value.filterName === name);
  assertIsDefined(filterOption);
  return filterOption;
}

export function Filters(props: {
  formFilters: DeepReadonly<FormFilter[]>;
  applyFilters: () => void;
  updateFilter: UpdateFilter;
  filtersExtended: boolean;
  msProps: DeepReadonly<MultiSelectProps<string>>;
  filterOptions: DeepReadonly<FilterOption[]>;
  toggleFilter: (filterName: string) => void;
}): GaVueComponent {
  const { t } = useI18n();
  return (
    <div class={["collapse", props.filtersExtended ? "show" : ""]}>
      <div>
        <div class="filtering-dropdown">
          <div class="filtering-dropdown__left-col">
            {props.formFilters
              .filter((filter) => filter.priority === "PRIMARY")
              .map((filter) => (
                <Filter filter={filter} updateFilter={props.updateFilter} msProps={props.msProps} filterOption={getFilterOptionByName(filter.name, props.filterOptions)} toggleFilter={props.toggleFilter} />
              ))}
          </div>
          <div class="filtering-dropdown__right-col">
            {props.formFilters
              .filter((filter) => filter.priority === "SECONDARY")
              .map((filter) => (
                <Filter filter={filter} updateFilter={props.updateFilter} msProps={props.msProps} filterOption={getFilterOptionByName(filter.name, props.filterOptions)} toggleFilter={props.toggleFilter} />
              ))}
          </div>
          <div>
            <GaButton onClick={props.applyFilters}>{t("general:button.apply")}</GaButton>
          </div>
        </div>
      </div>
    </div>
  );
}
