import { type DeepReadonly, nextTick, ref } from "vue";
import type { GetabstractAiProperties } from "@generated/model/getabstractAiProperties";
import { backgroundWorker } from "@/components/getabstract-ai/utils/getabstractAiBackgroundWorker";
import { delay } from "@utils/asyncUtils";
import { startTimer } from "@/components/getabstract-ai/utils/getabstractAiAnimationUtils";
import { createQuestion, fetchBookmarkChange, getQuestionAnswers, sendFeedback } from "@/components/getabstract-ai/utils/getabstractAiRequests";
import type { ActionableMiniView } from "@generated/model/actionableMiniView";
import type { GetabstractAiAnswerContextDoc } from "@generated/model/getabstractAiAnswerContextDoc";
import type { GetabstractAiAnalyticsEventVariant } from "@newgenerated/shared/schema";

export const WAITING_INTERVAL_IN_MS = 50;
export const STREAMING_TOKEN_PER_SEC = 10;
export const STREAMING_SPEED_UP_FACTOR = 0.005;

export type FeedbackMap = Map<string, boolean>;
export type BookmarkMap = Map<number, boolean>;
export type Failures = { feedback: boolean; bookmark: boolean };

export type ComponentInteractions = {
  question:
    | {
        kind: "BY_QUESTION";
        input: string;
        trending?: boolean;
      }
    | {
        kind: "BY_UUID";
        uuid: string;
      }
    | {
        kind: "RELATED";
        input: string;
      };
  giveFeedback: FeedbackMap;
  toggleBookmarks: BookmarkMap;
  dismissAlert: Failures;
};

export type QuestionAnswer = {
  question: string;
  questionUuid: string;
  detectedLanguageLabel: string | null;
  answer: string;
  contextDocs: DeepReadonly<GetabstractAiAnswerContextDoc[]>;
  relatedActionables: DeepReadonly<ActionableMiniView[]>;
  relatedQuestions: string[];
};

export type InitialUiState = {
  kind: "INITIAL";
};

export type LoadingUiState = {
  kind: "LOADING";
  value: {
    history: DeepReadonly<QuestionAnswer[]>;
    current: DeepReadonly<QuestionAnswer>;
  };
};

export type StreamingUiState = {
  kind: "STREAMING";
  value: {
    streamTokenCount: number;
    history: DeepReadonly<QuestionAnswer[]>;
    current: DeepReadonly<QuestionAnswer>;
  };
};

export type ErrorUiStatus = "ERROR_NO_ANSWER" | "ERROR_GENERAL";
export type CurrentUiStatus = "SUCCESS" | ErrorUiStatus;

export type IdleUiState = {
  kind: "IDLE";
  value: {
    history: DeepReadonly<QuestionAnswer[]>;
    current: DeepReadonly<QuestionAnswer> & {
      status: CurrentUiStatus;
    };
  };
};

export type ComponentUiState = (InitialUiState | LoadingUiState | StreamingUiState | IdleUiState) & {
  feedbackByUuid: DeepReadonly<FeedbackMap>;
  bookmarksByDataId: DeepReadonly<BookmarkMap>;
  failures: DeepReadonly<Failures>;
};

export type ComponentStoreActions = {
  submitInitialQuestion: (question: string, trending?: boolean) => void;
  giveFeedback: (questionUuid: string, isPositive: boolean) => void;
  toggleBookmark: (dataId: number, bookmarked: boolean) => void;
  chooseRelatedQuestion: (relatedQuestion: string) => void;
  loadByUuid: (questionUuid: string) => void;
  dismissAlert: (failureType: keyof Failures) => void;
};

export type ComponentStoreHelpers = {
  feedbackIsPositive: (questionUuid: string) => boolean | null;
  summaryIsBookmarked: (dataId: number) => boolean;
};

export type Store = {
  props: DeepReadonly<GetabstractAiProperties>;
  state: () => DeepReadonly<ComponentUiState>;
  actions: ComponentStoreActions;
  helpers: ComponentStoreHelpers;
  _forDebugging: {
    interactions: () => DeepReadonly<ComponentInteractions>;
  };
};

export function resetUiState(): ComponentUiState {
  return {
    kind: "INITIAL",
    feedbackByUuid: new Map(),
    bookmarksByDataId: new Map(),
    failures: { feedback: false, bookmark: false },
  };
}

export function resetInteractions(): ComponentInteractions {
  return { question: { kind: "BY_QUESTION", input: "" }, giveFeedback: new Map(), toggleBookmarks: new Map(), dismissAlert: { bookmark: false, feedback: false } };
}

export function createStore(props: DeepReadonly<GetabstractAiProperties>, analyticsEventVariant?: GetabstractAiAnalyticsEventVariant): Store {
  const interactions = ref<ComponentInteractions>(resetInteractions());
  const state = ref<ComponentUiState>(resetUiState());

  void backgroundWorker(
    {
      streamingTokensPerSec: STREAMING_TOKEN_PER_SEC,
      streamingSpeedUpFactor: STREAMING_SPEED_UP_FACTOR,
      analyticsEventVariant,
    },
    {
      getInteractions: () => interactions.value,
      updateUi: (newState) => {
        const questionHasChanged = newState.kind !== "INITIAL" && state.value.kind !== "INITIAL" && newState.value.current.questionUuid !== state.value.value.current.questionUuid;
        state.value = newState;
        if (questionHasChanged) {
          // A new question will collapse earlier questions. The following will help focus on the asked question no matter the layout shift.
          void nextTick(() => {
            const selectedQuestion = document.getElementById("selectedQuestion");
            selectedQuestion?.scrollIntoView({ block: "center" });
          });
        }
      },
      processedBookmark: (dataId) => {
        const updatedToggleBookmarks = new Map(interactions.value.toggleBookmarks);
        updatedToggleBookmarks.delete(dataId);
        interactions.value = { ...interactions.value, toggleBookmarks: updatedToggleBookmarks };
      },
      processedFeedback: (questionUuid) => {
        const updatedGiveFeedback = new Map(interactions.value.giveFeedback);
        updatedGiveFeedback.delete(questionUuid);
        interactions.value = { ...interactions.value, giveFeedback: updatedGiveFeedback };
      },
      processedAlertDismissal: (failureType) => {
        interactions.value = { ...interactions.value, dismissAlert: { ...interactions.value.dismissAlert, [failureType]: false } };
      },
      initQuestionAnswer: createQuestion,
      getQuestionAnswers: getQuestionAnswers,
      giveFeedback: async (questionUuid, isPositive) => await sendFeedback({ questionUuid, isPositive }),
      toggleBookmark: async (dataId, bookmarked) => {
        await fetchBookmarkChange(dataId, bookmarked);
      },
      delay: () => delay(WAITING_INTERVAL_IN_MS),
      isAborted: () => false,
      startTimer,
    },
  );

  const actions: ComponentStoreActions = {
    submitInitialQuestion: (question, trending) => {
      interactions.value = { ...interactions.value, question: { kind: "BY_QUESTION", input: question, trending } };
    },
    giveFeedback(questionUuid, isPositive) {
      const updatedGiveFeedback = new Map(interactions.value.giveFeedback);
      updatedGiveFeedback.set(questionUuid, isPositive);
      interactions.value = { ...interactions.value, giveFeedback: updatedGiveFeedback };
    },
    toggleBookmark(dataId, bookmarked) {
      const updatedToggleBookmarks = new Map(interactions.value.toggleBookmarks);
      updatedToggleBookmarks.set(dataId, bookmarked);
      interactions.value = { ...interactions.value, toggleBookmarks: updatedToggleBookmarks };
    },
    chooseRelatedQuestion(relatedQuestion) {
      interactions.value = { ...interactions.value, question: { kind: "RELATED", input: relatedQuestion } };
    },
    loadByUuid(questionUuid) {
      interactions.value = { ...interactions.value, question: { kind: "BY_UUID", uuid: questionUuid } };
    },
    dismissAlert(failureType) {
      interactions.value = { ...interactions.value, dismissAlert: { ...interactions.value.dismissAlert, [failureType]: true } };
    },
  };

  const helpers: ComponentStoreHelpers = {
    feedbackIsPositive(questionUuid) {
      return state.value.feedbackByUuid.get(questionUuid) ?? null;
    },
    summaryIsBookmarked(dataId) {
      return state.value.bookmarksByDataId.get(dataId) ?? false;
    },
  };

  if (props.questionUuid !== null && props.questionUuid.length === 36) {
    // QuestionUuid has been passed through
    actions.loadByUuid(props.questionUuid);
  } else if (props.question !== null && props.question.trim().length > 0) {
    // Question has been passed through
    actions.submitInitialQuestion(props.question);
  }

  return {
    props,
    state: () => state.value,
    actions,
    helpers,
    _forDebugging: {
      interactions: () => interactions.value,
    },
  };
}
