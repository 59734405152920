import { ensureNonNull } from "@utils/assertion";
import { type WarmupV2EditForm } from "@newgenerated/shared/schema";
import { serialize_WarmupV2EditForm, deserialize_WarmupV2EditForm } from "@newgenerated/shared/mapper";

export { type WarmupV2EditForm } from "@newgenerated/shared/schema";
export const parseWarmupV2EditForm = (data: unknown): WarmupV2EditForm => {
  return ensureNonNull(deserialize_WarmupV2EditForm(JSON.stringify(data)));
};
export const renderWarmupV2EditForm = (data: WarmupV2EditForm): any => {
  return JSON.parse(serialize_WarmupV2EditForm(data));
};
