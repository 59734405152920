import type { GaVueComponent } from "@/common/vueUtils";
import type { DeepReadonly } from "vue";
import type { ComponentUiState } from "@/components/getabstract-ai/utils/getabstractAiStore";
import { SelectedQuestion } from "@/components/getabstract-ai/uiFragments/SelectedQuestion";
import { AnswerCard } from "@/components/getabstract-ai/uiFragments/Answer";
import { Feedback } from "@/components/getabstract-ai/uiFragments/Feedback";
import { References } from "@/components/getabstract-ai/uiFragments/References";
import { RelatedActionables } from "@/components/getabstract-ai/uiFragments/RelatedActionables";
import { RelatedQuestions } from "@/components/getabstract-ai/uiFragments/RelatedQuestions";

export function CurrentQuestionAnswer(props: {
  state: DeepReadonly<ComponentUiState>;
  giveFeedback: (questionUuid: string, isPositive: boolean) => void;
  toggleBookmark: (dataId: number, bookmarked: boolean) => void;
  chooseRelatedQuestion: (relatedQuestion: string) => void;
  feedbackIsPositive: (questionUuid: string) => boolean | null;
  summaryIsBookmarked: (dataId: number) => boolean;
}): GaVueComponent | null {
  if (props.state.kind !== "IDLE" || props.state.value.current.status !== "SUCCESS") {
    return null;
  }

  const { current } = props.state.value;

  return (
    <div class="d-grid gap-5">
      <SelectedQuestion question={current.question} isRelatedQuestion={props.state.value.history.length > 0} detectedLanguageLabel={current.detectedLanguageLabel} />
      <AnswerCard answer={current.answer} contextDocs={current.contextDocs} questionUuid={current.questionUuid} />
      <Feedback question={current.question} questionUuid={current.questionUuid} feedbackIsPositive={props.feedbackIsPositive(current.questionUuid)} giveFeedback={props.giveFeedback} showMailFeedbackLink={true} />
      <References contextDocs={current.contextDocs} answer={current.answer} questionUuid={current.questionUuid} toggleBookmark={props.toggleBookmark} summaryIsBookmarked={props.summaryIsBookmarked} />
      <RelatedActionables relatedActionables={current.relatedActionables} questionUuid={current.questionUuid} showExplanation={true} />
      <RelatedQuestions relatedQuestions={current.relatedQuestions} chooseRelatedQuestion={props.chooseRelatedQuestion} />
    </div>
  );
}
