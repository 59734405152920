import type { GaVueComponent } from "@/common/vueUtils";

export function LoadingComponent(props: { large: boolean }): GaVueComponent {
  return (
    <div class="placeholder-wave mb-5">
      <div>
        <span class="placeholder col-3 mb-4" style="height:3.625rem"></span>
      </div>
      {props.large ? (
        <>
          <div class="row row-cols-1 row-cols-md-2 row-gap-3">
            <div class="col">
              <span class="placeholder fullSearch__placeholder"></span>
            </div>
            <div class="col">
              <span class="placeholder fullSearch__placeholder"></span>
            </div>
            <div class="col">
              <span class="placeholder fullSearch__placeholder"></span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-gap-3">
            <div class="col">
              <span class="placeholder fullSearch__placeholder fullSearch__placeholder-mini"></span>
            </div>
            <div class="col">
              <span class="placeholder fullSearch__placeholder fullSearch__placeholder-mini"></span>
            </div>
            <div>
              <span class="placeholder fullSearch__placeholder fullSearch__placeholder-mini"></span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
