import "./_prospect-flower.scss";
import { registerModule } from "@/Registry";
import { assert } from "@utils/assertion";

function init(flower: Element): void {
  assert(flower instanceof HTMLDivElement);
  const flowerLeafs = flower.querySelectorAll<HTMLDivElement>("[data-ga-flower-leaf]");

  flowerLeafs.forEach((l, index) => {
    l.querySelector<SVGGElement>("[data-ga-flower-leaf-sg]")?.addEventListener("mouseenter", () => {
      flowerLeafs.forEach((leaf) => {
        leaf.classList.remove("active");
      });
      l.classList.add("active");
      flower.style.setProperty("--lastActiveLeaf", index + "");
      flower.classList.add("animated");
    });
  });
}

registerModule("[data-ga-flower]", init);
