<template>
  <a :href="href" :target="linkTarget" class="channel-card-mini">
    <img :src="coverUrl" alt="" class="channel-card-mini__img" loading="lazy" />
    <div class="channel-card-mini__title">
      {{ channel.title }}
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import "./_channel-card-mini.scss";
import type { ChannelMetadata } from "@newgenerated/shared/schema";

export default defineComponent({
  props: {
    channel: {
      type: Object as PropType<ChannelMetadata>,
      required: true,
    },
    linkTarget: {
      type: String,
    },
    params: {
      type: Object as PropType<URLSearchParams>,
    },
  },
  setup(props) {
    const url = new URL("/channels/" + props.channel.name + "/" + props.channel.channelId, window.location.origin);
    if (props.params !== undefined) {
      for (const [key, value] of props.params) {
        url.searchParams.append(key, value);
      }
    }
    const coverUrl = props.channel.coverUri + "?s=L";
    return {
      href: url.href,
      coverUrl,
    };
  },
});
</script>
