import type { GetabstractAiAnalyticsFeedbackEventForm } from "@generated/model/getabstractAiAnalyticsFeedbackEventForm";
import type { GetabstractAiQuestionAnswers } from "@generated/model/getabstractAiQuestionAnswers";
import type { GetabstractAiQuestion } from "@generated/model/getabstractAiQuestion";
import { getHttpClient } from "@utils/httpClient";
import { deserialize_GetabstractAiQuestionAnswers, serialize_GetabstractAiAnalyticsFeedbackEventForm, serialize_GetabstractAiQuestion } from "@newgenerated/shared/mapper";
import { getCsrfToken } from "@utils/csrfUtils";
import type { GetabstractAiAnalyticsEventVariant } from "@newgenerated/shared/schema";

export type CreateQuestionProperties = {
  question: string;
  previousQuestionUuid: string | null;
  relatedQuestionStep: number;
  trendingQuestion: boolean;
  blacklistQuestions: string[];
  analyticsEventVariant: GetabstractAiAnalyticsEventVariant | null;
};

export type CreateQuestionPropertiesExtended = CreateQuestionProperties & { questionUuid: string };

export async function createQuestion({ question, questionUuid, previousQuestionUuid, relatedQuestionStep, trendingQuestion, blacklistQuestions, analyticsEventVariant }: CreateQuestionPropertiesExtended): Promise<void> {
  const form: GetabstractAiQuestion = {
    question,
    questionUuid,
    previousQuestionUuid,
    relatedQuestionStep,
    trendingQuestion,
    blacklistQuestions,
    analyticsEventVariant,
  };

  await getHttpClient().request<string>({
    headers: { "Content-Type": "application/json" },
    url: `/gaapi/search/getabstract-ai`,
    method: "POST",
    params: {},
    data: form,
    transformRequest: [
      (data, _headers) => {
        return serialize_GetabstractAiQuestion(data);
      },
    ],
  });
}

export async function getQuestionAnswers(questionUuid: string): Promise<GetabstractAiQuestionAnswers> {
  return await getHttpClient()
    .request({
      headers: { "Content-Type": "application/json" },
      url: `/gaapi/search/getabstract-ai/questionAnswers/${questionUuid}`,
      method: "GET",
      transformResponse: [
        (data, _header) => {
          return deserialize_GetabstractAiQuestionAnswers(data);
        },
      ],
    })
    .then((response) => {
      return response.data;
    });
}

export async function sendFeedback(form: GetabstractAiAnalyticsFeedbackEventForm): Promise<void> {
  return await getHttpClient().request({
    headers: { "Content-Type": "application/json" },
    url: "/gaapi/search/getabstract-ai/analytics/feedback",
    method: "POST",
    data: form,
    transformRequest: [
      (data, _headers) => {
        return serialize_GetabstractAiAnalyticsFeedbackEventForm(data);
      },
    ],
  });
}

export async function fetchBookmarkChange(dataId: number, bookmarked: boolean): Promise<void> {
  const response = await fetch(`/summary-ix/${dataId}/bookmark`, { method: bookmarked ? "POST" : "DELETE", headers: { "X-XSRF-Token": await getCsrfToken() } });
  if (!response.ok) {
    throw Error(`Could not fetch book mark change. Bookmarked: ${bookmarked}, Summary ID: ${dataId}`);
  }
}
