import { ensureNonNull } from "@utils/assertion";
import { FeatureType } from "@newgenerated/shared/schema";
import { serialize_FeatureType, deserialize_FeatureType } from "@newgenerated/shared/mapper";

export { FeatureType } from "@newgenerated/shared/schema";
export const parseFeatureType = (data: unknown): FeatureType => {
  return ensureNonNull(deserialize_FeatureType(JSON.stringify(data)));
};
export const renderFeatureType = (data: FeatureType): any => {
  return JSON.parse(serialize_FeatureType(data));
};
