import type { FeatureType } from "@newgenerated/shared/schema";
import { delay } from "@utils/asyncUtils";
import { getFeatures } from "@generated/api/featureControllerApi";

type FeatureState =
  | {
      type: "MISSING" | "LOADING";
    }
  | {
      type: "AVAILABLE";
      features: FeatureType[];
    };
let state: FeatureState = { type: "MISSING" };

async function setupFeatures(): Promise<FeatureType[]> {
  while (true) {
    if (state.type === "MISSING") {
      // Load available features for the first time
      state = { type: "LOADING" };
      const features = await getFeatures();
      state = { type: "AVAILABLE", features };
      return features;
    }

    if (state.type === "AVAILABLE") {
      // features have already been set up
      return state.features;
    }

    // There is a request for available features in process
    await delay(100);
  }
}

export async function hasFeature(feature: FeatureType): Promise<boolean> {
  const features = await setupFeatures();
  return features.includes(feature);
}
