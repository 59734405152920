import "./VueDemo.scss";
import { defineComponent, type Ref, ref } from "vue";

import type { ExperimentalDemoModel } from "@newgenerated/experimental/schema";
import { getExperimentalDemoModel } from "@newgenerated/experimental/stubs";

export const ExperimentalGeneratorDemo = defineComponent({
  inheritAttrs: false,
  props: {},
  setup(_props) {
    const state: Ref<{ kind: "data"; data: ExperimentalDemoModel } | { kind: "idle" } | { kind: "loading" } | { kind: "error"; msg: string }> = ref({
      kind: "idle",
    });

    return () => (
      <div class="vue-demo-component p-4">
        <button
          onClick={async () => {
            if (state.value.kind === "loading") {
              return;
            }
            state.value = { kind: "loading" };
            const response = await getExperimentalDemoModel();
            if (response.kind === "error") {
              state.value = { kind: "error", msg: response.message };
            } else {
              state.value = { kind: "data", data: response.data };
            }
          }}>
          Request from server
        </button>
        <pre>
          {(() => {
            switch (state.value.kind) {
              case "idle":
                return "idle";
              case "loading":
                return "loading...";
              case "error":
                return `error: ${state.value.msg}`;
              case "data":
                return JSON.stringify(state.value.data);
            }
          })()}
        </pre>
      </div>
    );
  },
});
