import type { QuestionAnswer } from "@/components/getabstract-ai/utils/getabstractAiStore";
import type { DeepReadonly } from "vue";
import type { GetabstractAiQuestion } from "@generated/model/getabstractAiQuestion";
import type { GetabstractAiQuestionAnswers } from "@generated/model/getabstractAiQuestionAnswers";
import type { AnimationTimer } from "@/components/getabstract-ai/utils/getabstractAiAnimationUtils";
import { createRunStreamingAnimationEndless, createRunStreamingAnimationUntilMaxReached } from "@/components/getabstract-ai/utils/getabstractAiAnimationUtils";
import type { AbortableFunction } from "@utils/asyncUtils";
import { runAbortableFunctions } from "@utils/asyncUtils";
import type { GetabstractAiAnalyticsEventVariant } from "@newgenerated/shared/schema";

export async function tryInitializeQuestion(props: {
  isTrendingQuestion: boolean;
  analyticsEventVariant: GetabstractAiAnalyticsEventVariant | null;
  history: DeepReadonly<QuestionAnswer[]>;
  current: DeepReadonly<QuestionAnswer>;
  initQuestionAnswer: (props: GetabstractAiQuestion) => Promise<void>;
}): Promise<{ kind: "SUCCESS" | "ERROR" }> {
  const blacklistQuestions: string[] = [...props.history.map((e) => e.question), props.current.question];
  const relatedQuestionStep = props.history.length;

  try {
    await props.initQuestionAnswer({
      question: props.current.question,
      questionUuid: props.current.questionUuid,
      trendingQuestion: props.isTrendingQuestion,
      blacklistQuestions,
      previousQuestionUuid: props.history.at(-1)?.questionUuid ?? null,
      relatedQuestionStep,
      analyticsEventVariant: props.analyticsEventVariant,
    });

    return {
      kind: "SUCCESS",
    };
  } catch (_: unknown) {
    return {
      kind: "ERROR",
    };
  }
}

export type PollingResult =
  | {
      kind: "SUCCESS";
      value: GetabstractAiQuestionAnswers;
    }
  | { kind: "ERROR" }
  | { kind: "CANCELLED" };

export function createRunPolling(
  props: {
    questionUuid: string;
  },
  actions: {
    getQuestionAnswers: (questionUuid: string) => Promise<GetabstractAiQuestionAnswers>;
    runUntil: (questionAnswers: GetabstractAiQuestionAnswers) => boolean;
    updateWhileProcessing: (value: GetabstractAiQuestionAnswers) => Promise<void>;
  },
): AbortableFunction<PollingResult> {
  return async (signal: AbortSignal) => {
    while (true) {
      if (signal.aborted) {
        return { kind: "CANCELLED" };
      }
      try {
        const questionAnswers = await actions.getQuestionAnswers(props.questionUuid);
        await actions.updateWhileProcessing(questionAnswers);
        if (actions.runUntil(questionAnswers)) {
          return { kind: "SUCCESS", value: questionAnswers };
        }
      } catch (_: unknown) {
        return { kind: "ERROR" };
      }
    }
  };
}

export function createProcessQuestion(
  props: {
    current: DeepReadonly<QuestionAnswer>;
    history: DeepReadonly<QuestionAnswer[]>;
    isTrendingQuestion: boolean;
    analyticsEventVariant: GetabstractAiAnalyticsEventVariant | null;
    streamingTokensPerSec: number;
    streamingSpeedUpFactor: number;
  },
  actions: {
    initQuestionAnswer: (props: GetabstractAiQuestion) => Promise<void>;
    getQuestionAnswers: (questionUuid: string) => Promise<GetabstractAiQuestionAnswers>;
    updateWhileProcessing: (value: GetabstractAiQuestionAnswers) => Promise<void>;
    startTimer: () => AnimationTimer;
    delay: () => Promise<void>;
    updateTokenCount: (tokenCount: number) => void;
  },
): AbortableFunction<PollingResult> {
  return async (signal: AbortSignal) => {
    const initResult = await tryInitializeQuestion({
      isTrendingQuestion: props.isTrendingQuestion,
      analyticsEventVariant: props.analyticsEventVariant,
      history: props.history,
      current: props.current,
      initQuestionAnswer: actions.initQuestionAnswer,
    });

    if (initResult.kind === "ERROR") {
      return { kind: "ERROR" };
    }

    const runPollingUntilFirstWord = createRunPolling(
      {
        questionUuid: props.current.questionUuid,
      },
      {
        getQuestionAnswers: actions.getQuestionAnswers,
        runUntil: (questionAnswers) => {
          return questionAnswers.current.status !== "PROCESSING" || questionAnswers.current.answer.length > 0;
        },
        updateWhileProcessing: (value) => {
          return actions.updateWhileProcessing(value);
        },
      },
    );

    const resultUntilFristWord = await runAbortableFunctions([runPollingUntilFirstWord], () => signal.aborted, actions.delay);

    if (resultUntilFristWord.kind !== "SUCCESS" || resultUntilFristWord.value.current.status !== "PROCESSING") {
      return resultUntilFristWord;
    }

    let answer = "";
    let alreadyAnimatedTokens = 0;
    const runPollingUntilDone = createRunPolling(
      {
        questionUuid: props.current.questionUuid,
      },
      {
        getQuestionAnswers: actions.getQuestionAnswers,
        runUntil: (questionAnswers) => questionAnswers.current.status !== "PROCESSING",
        updateWhileProcessing: (questionAnswers) => {
          answer = questionAnswers.current.answer;
          return actions.updateWhileProcessing(questionAnswers);
        },
      },
    );

    const runStreamingAnimation = createRunStreamingAnimationEndless(
      {
        streamingTokensPerSec: props.streamingTokensPerSec,
      },
      {
        startTimer: actions.startTimer,
        delay: actions.delay,
        getMaxTokenAvailable: () => answer.split(" ").length,
        updateTokenCount: (tokenCount) => {
          alreadyAnimatedTokens = tokenCount;
          return actions.updateTokenCount(tokenCount);
        },
      },
    );

    const pollingUntilDoneResult = await runAbortableFunctions([runPollingUntilDone, runStreamingAnimation], () => signal.aborted, actions.delay);

    if (pollingUntilDoneResult.kind !== "SUCCESS" || pollingUntilDoneResult.value.current.status !== "FINISHED") {
      return pollingUntilDoneResult;
    }

    const runStreamingAnimationUntilDone = createRunStreamingAnimationUntilMaxReached(
      {
        streamingTokensPerSec: props.streamingTokensPerSec,
        streamingSpeedUpFactor: props.streamingSpeedUpFactor,
        alreadyAnimatedTokens: alreadyAnimatedTokens,
      },
      {
        startTimer: actions.startTimer,
        delay: actions.delay,
        getMaxTokenAvailable: () => answer.split(" ").length,
        updateTokenCount: actions.updateTokenCount,
      },
    );

    const streamingResult = await runAbortableFunctions([runStreamingAnimationUntilDone], () => signal.aborted, actions.delay);

    if (streamingResult.kind === "CANCELLED") {
      return { kind: "CANCELLED" };
    } else {
      return pollingUntilDoneResult;
    }
  };
}
