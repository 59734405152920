import { ensureNonNull } from "@utils/assertion";
import { type WarmupV2ValidationResult } from "@newgenerated/shared/schema";
import { serialize_WarmupV2ValidationResult, deserialize_WarmupV2ValidationResult } from "@newgenerated/shared/mapper";

export { type WarmupV2ValidationResult } from "@newgenerated/shared/schema";
export const parseWarmupV2ValidationResult = (data: unknown): WarmupV2ValidationResult => {
  return ensureNonNull(deserialize_WarmupV2ValidationResult(JSON.stringify(data)));
};
export const renderWarmupV2ValidationResult = (data: WarmupV2ValidationResult): any => {
  return JSON.parse(serialize_WarmupV2ValidationResult(data));
};
