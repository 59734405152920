// @ts-ignore
import { gaApiCall } from "@utils/httpClient";
// @ts-ignore
import { renderJsonWithNumbersAsBigint } from "@utils/json";
import * as schema from "@newgenerated/experimental/schema";
import * as mapper from "@newgenerated/experimental/mapper";
export async function getExperimentalModel(props: { experimentId: number; experimentQuery: number }): Promise<{ kind: "success"; data: schema.ExperimentModel } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    renderJsonWithNumbersAsBigint(
      ((value) => {
        return value;
      })(props.experimentId),
    ),
  );
  path += "?";
  path += new URLSearchParams({
    experimentQuery: renderJsonWithNumbersAsBigint(
      ((value) => {
        return value;
      })(props.experimentQuery),
    ),
  }).toString();
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = mapper.deserialize_ExperimentModel(response.text);
  if (deserialized == null) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized };
}

export async function updateExperimentModel(props: { body: schema.ExperimentModel; experimentId: number }): Promise<{ kind: "success"; data: schema.ExperimentModel } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    renderJsonWithNumbersAsBigint(
      ((value) => {
        return value;
      })(props.experimentId),
    ),
  );
  const response = await gaApiCall({ path: path, method: "PUT", requestBody: mapper.serialize_ExperimentModel(props.body) });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = mapper.deserialize_ExperimentModel(response.text);
  if (deserialized == null) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized };
}

export async function createExperimentModel(props: { body: schema.ExperimentModel; experimentId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    renderJsonWithNumbersAsBigint(
      ((value) => {
        return value;
      })(props.experimentId),
    ),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: mapper.serialize_ExperimentModel(props.body) });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function getExperimentalDemoModel(): Promise<{ kind: "success"; data: schema.ExperimentalDemoModel } | { kind: "error"; message: string }> {
  const path = "/gaapi/experimental/demo";
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = mapper.deserialize_ExperimentalDemoModel(response.text);
  if (deserialized == null) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized };
}

export async function putExperimentalDemoModel(props: { body: schema.ExperimentalDemoModel }): Promise<{ kind: "success"; data: schema.ExperimentalDemoModel } | { kind: "error"; message: string }> {
  const path = "/gaapi/experimental/demo";
  const response = await gaApiCall({ path: path, method: "PUT", requestBody: mapper.serialize_ExperimentalDemoModel(props.body) });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = mapper.deserialize_ExperimentalDemoModel(response.text);
  if (deserialized == null) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized };
}
